<template>
  <div class="nextCase">
    <div class="setMenuColor" data-menu-color="light" v-if="caseName == 'abd'">
      <div class="oldTemplate">
        <div class="abdBanner nextCaseBanner oldTemplate">
          <div class="container">
            <p class="nextCase--after">Следующий проект</p>
            <h1 class="abdBanner--title">Ак Барс Дом</h1>
            <p class="abdBanner--desc">
              Сайт-каталог крупного застройщика<br class="show1100" />
              Республики Татарстан
            </p>
            <div class="nextCase__loaderContainer">
              <div class="nextCase__loader" :style="{ width: progress + '%' }"></div>
            </div>
          </div>
          <div class="abdLines">
            <div class="
                      abdLines--line2
                      animatedLine animatedLine--down
                      animateOnLoad
                    "></div>
            <div class="
                      abdLines--line1
                      animatedLine animatedLine--up
                      animateOnLoad
                    "></div>
            <div class="
                      abdLines--line3
                      animatedLine animatedLine--down
                      animateOnLoad
                    "></div>
            <div class="
                      abdLines--line5
                      animatedLine animatedLine--down
                      animateOnLoad
                    "></div>
            <div class="
                      abdLines--line4
                      animatedLine animatedLine--down
                      animateOnLoad
                    "></div>
            <div class="
                      abdLines--line6
                      animatedLine animatedLine--up
                      animateOnLoad
                    "></div>
            <div class="
                      abdLines--line7
                      animatedLine animatedLine--up
                      animateOnLoad
                    " :style="{
                      backgroundImage:
                        'url(' + require(`@/assets/img/abd/lineBack.png`) + ')',
                    }"></div>
            <div class="
                      abdLines--line8
                      animatedLine animatedLine--down
                      animateOnLoad
                    " :style="{
                      backgroundImage:
                        'url(' + require(`@/assets/img/abd/lineBack.png`) + ')',
                    }"></div>
            <div class="
                      abdLines--line9
                      animatedLine animatedLine--up
                      animateOnLoad
                    " :style="{
                      backgroundImage:
                        'url(' + require(`@/assets/img/abd/lineBack2.png`) + ')',
                    }"></div>
            <div class="
                      abdLines--line10
                      animatedLine animatedLine--down
                      animateOnLoad
                    " :style="{
                      backgroundImage:
                        'url(' + require(`@/assets/img/abd/lineBack3.png`) + ')',
                    }"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="caseName == 'medar'">
      <div class="oldTemplate">
        <div class="banner medar" :style="{
          backgroundImage:
            'url(' + require(`@/assets/img/medar/banner2.jpg`) + ')',
        }">
          <div class="container bannerContainer">
            <div class="banner__main">
              <p class="nextCase--after color-black">Следующий проект</p>
              <p class="banner--title">Medar Beauty</p>
              <p class="banner--desc">Интернет-магазин корейской косметики</p>
              <div class="nextCase__loaderContainer">
                <div class="nextCase__loader" :style="{ width: progress + '%' }"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="caseName == 'centrserdce'">
      <div class="oldTemplate">
        <div class="banner center centrSerdce">
          <div class="banner__abs_container">
            <video :src="require(`@/assets/img_new/projectsSlider/serdce.mp4`)" autoplay loop muted="" playsinline=""
              :poster="
                require(`@/assets/img_new/projectsSlider/serdce-poster.jpg`)
              " class="banner__video"></video>
          </div>
          <div class="container bannerContainer">
            <div class="banner__main">
              <p class="nextCase--after color-black">Следующий проект</p>
              <p class="banner--title big">Центр Сердце</p>
              <p class="banner--desc">
                Узкопрофильный медицинский центр в г.Казань
              </p>
              <div class="nextCase__loaderContainer">
                <div class="nextCase__loader" :style="{ width: progress + '%' }"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="caseName == 'dymov'">
      <div class="dymovBannerContainer setMenuColor" data-menu-color="light">
        <div class="dymovBanner">
          <div class="container4">
            <p class="nextCase--after">Следующий проект</p>
            <h1 class="dymovBanner--title">Дымов</h1>
            <p class="dymovBanner--desc">
              Интернет-магазин федерального производителя колбас,<br />
              мясных полуфабрикатов, снеков и деликатесов
            </p>
            <div class="nextCase__loaderContainer">
              <div class="nextCase__loader" :style="{ width: progress + '%' }"></div>
            </div>
          </div>
          <div class="dymovBanner--video">
            <video :src="require(`@/assets/img_new/dymov/banner.mp4`)" playsinline autoplay muted loop
              ref="sliderVideo"></video>
          </div>
        </div>
      </div>
    </div>
    <div v-if="caseName == 'mpkabel'">
      <div class="mpk_banner setMenuColor" data-menu-color="light">
        <div class="container3 mpk_banner__contaner">
          <p class="nextCase--after">Следующий проект</p>
          <h1 class="dymovBanner--title">
            <span>Марпосад</span><span class="mp_primary">кабель</span>
          </h1>
          <p class="dymovBanner--desc">
            Сайт-каталог завода кабельной продукции Марпасадкабель
          </p>
          <div class="nextCase__loaderContainer">
            <div class="nextCase__loader" :style="{ width: progress + '%' }"></div>
          </div>
        </div>
        <div class="mpk_banner__video">
          <video :src="require(`@/assets/img_new/mpkabel/videos/cable_video.mp4`)" playsinline autoplay muted loop
            ref="sliderVideo"></video>
        </div>
      </div>
    </div>

    <div v-if="caseName == 'kk'">
      <div class="kk_baner">
        <div class="container kk_baner__content">
          <div class="kk_baner__title">Кабинет клиента <br> Капитал LIFE</div>
          <div class="kk_baner__subtitle">Разработка кабинета клиента для страховой компании</div>
          <div class="nextCase__loaderContainer">
            <div class="nextCase__loader" :style="{ width: progress + '%' }"></div>
          </div>
        </div>

        <div class="kk_anim kk_baner__anim">
          <img class="kk_anim__screen1" :src="require(`@/assets/img_new/kk/kk_anim/screen1.png`)" alt="" />
          <img class="kk_anim__screen2" :src="require(`@/assets/img_new/kk/kk_anim/screen2.png`)" alt="" />
          <img class="kk_anim__square1" :src="require(`@/assets/img_new/kk/kk_anim/square1.svg`)" alt="" />
          <img class="kk_anim__square2" :src="require(`@/assets/img_new/kk/kk_anim/square2.svg`)" alt="" />
          <img class="kk_anim__square3" :src="require(`@/assets/img_new/kk/kk_anim/square3.svg`)" alt="" />
          <img class="kk_anim__square4" :src="require(`@/assets/img_new/kk/kk_anim/square4.svg`)" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass">
@import '@/assets/new_sass/NextCase'
@import '@/assets/sass/case/Banner'
</style>

<script>
export default {
  props: ["caseName"],
  data: () => ({
    progress: 0,
    scrollToEnd: false,
    progressInterval: null,
  }),
  mounted() {
    var comp = this;

    window.addEventListener("scroll", function () {
      comp.checkPositionScroll();
    });

    comp.initAnimatedLinesPos();
    comp.animateLines();

    window.addEventListener("scroll", function () {
      comp.animateLines();
    });

    window.addEventListener("resize", function () {
      comp.initAnimatedLinesPos();
      comp.animateLines();
      comp.bannerVideoSize();
    });

    var elements = this.$el.getElementsByClassName("animateOnLoad");

    for (var index = 0; index < elements.length; index++) {
      var element = elements[index];
      element.classList.add("animate");
    }
    setTimeout(function () {
      var elements = comp.$el.getElementsByClassName("animateOnLoad");
      for (var index = 0; index < elements.length; index++) {
        var element = elements[index];
        element.classList.remove("animate");
        element.classList.remove("animateOnLoad");
      }
      comp.initAnimatedLinesPos();
    }, 700);
  },
  methods: {
    startProgress: function () {
      var comp = this;

      comp.progressInterval = setInterval(function () {
        comp.progress += 1;
        if (comp.progress >= 100) {
          clearInterval(comp.progressInterval);
          comp.$emit("openNextCase");
        }
      }, 30);
    },
    endProgress: function () {
      var comp = this;
      clearInterval(comp.progressInterval);
      comp.progress = 0;
    },
    checkPositionScroll: function () {
      var comp = this;
      if (
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight
      ) {
        comp.scrollToEnd = true;
      } else {
        comp.scrollToEnd = false;
      }
    },

    initAnimatedLinesPos: function () {
      var elements = this.$el.getElementsByClassName("animatedLine");
      for (let element of elements) {
        element.style.transform = "rotate(45deg)";
        var elementHeight = element.offsetHeight;
        var elementTopPos =
          element.getBoundingClientRect().top +
          document.documentElement.scrollTop;
        var elementBottomPos = elementTopPos + elementHeight;
        element.dataset.topPos = elementTopPos;
        element.dataset.bottomPos = elementBottomPos;
      }
    },
    animateLines: function () {
      var scrollTop = document.documentElement.scrollTop;
      var windowHeight = window.innerHeight;
      var windowWidth = window.innerWidth;
      var windowBottomPos = scrollTop + windowHeight;

      var elements = this.$el.getElementsByClassName("animatedLine");

      for (var index = 0; index < elements.length; index++) {
        var element = elements[index];
        if (element.classList.contains("animateOnLoad")) {
          continue;
        }
        var direction = "down";
        if (element.classList.contains("animatedLine--up")) {
          direction = "up";
        }

        var elementHeight = element.offsetHeight;
        var elementTopPos = element.dataset.topPos;
        var elementBottomPos = element.dataset.bottomPos;
        if (windowBottomPos > elementTopPos && scrollTop < elementBottomPos) {
          var percent =
            1 - (elementBottomPos - scrollTop) / (windowHeight + elementHeight);

          var position = percent * 100 * (windowWidth / 1920) * 1.2;

          if (direction == "up") {
            element.style.transform =
              "translate(" +
              position +
              "px, " +
              -position +
              "px) rotate(45deg)";
          } else {
            element.style.transform =
              "translate(" +
              -position +
              "px, " +
              position +
              "px) rotate(45deg)";
          }
        }
      }
    },
    bannerVideoSize: function () {
      var comp = this;
      setTimeout(function () {
        var video = comp.$refs["sliderVideo"];
        var parent = video.closest(".mpk_banner__video");
        video.style.height = "auto";
        video.style.width = "auto";

        var containerHeight = parent.offsetHeight;
        var containerWidth = parent.offsetWidth;
        var videoHeight = video.offsetHeight;
        var videoWidth = video.offsetWidth;

        if (containerHeight / containerWidth > containerWidth / videoWidth) {
          video.style.height = containerHeight + 10 + "px";
        } else {
          video.style.width = containerWidth + 10 + "px";
        }
      });
    },
  },
  watch: {
    scrollToEnd: function () {
      if (this.scrollToEnd) {
        this.startProgress();
      } else {
        this.endProgress();
      }
    },
  },
};
</script>